<template>
  <validation-observer ref="create">
    <b-card no-body>
      <b-card-header header-bg-variant="success">
        <b-card-title style="color:white">
          <feather-icon icon="InfoIcon" size="20"/> Thêm Giáo Viên
        </b-card-title>
      </b-card-header>
      <b-container style="padding: 20px">
        <b-row>
          <b-col sm="12" md="8">
            <b-row>
              <b-col md="12">
                <b-form-group
                  label="Email"
                  label-for="h-email"
                  label-cols-md="3"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required|email"
                    name="Email"
                    :customMessages="{['required']: 'Không được để trống'}"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="MailIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="h-email"
                        v-model="form.email"
                        :state="errors.length > 0 ? false:null"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--pass-->
              <b-col md="12">
                <b-form-group
                  label="Mật khẩu"
                  label-for="h-password"
                  label-cols-md="3"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required|password"
                    name="Password"
                    :customMessages="{['required']: 'Không được để trống',
                      ['password']: 'Mật khẩu phải chứa ít nhất một chữ hoa, một chữ thường, một ký tự đặc biệt và một chữ số'}"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <feather-icon icon="LockIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="h-password"
                        type="password"
                        v-model="form.password"
                        :state="errors.length > 0 ? false:null"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--full name-->
              <b-col md="12">
                <b-form-group
                  label="Họ và Tên"
                  label-for="h-full-name"
                  label-cols-md="3"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="FullName"
                    :customMessages="{['required']: 'Không được để trống'}"
                  >
                      <b-form-input
                        id="h-full-name"
                        v-model="form.full_name"
                        :state="errors.length > 0 ? false:null"
                      />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- phone -->
              <b-col md="12">
                <b-form-group
                  label="Số Điện Thoại"
                  label-for="h-phone-number"
                  label-cols-md="3"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required|integer"
                    name="PhoneNumber"
                    :customMessages="{['required']: 'Không được để trống'}"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <feather-icon icon="PhoneIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="h-phone-number"
                        type="number"
                        v-model="form.phoneNumber"
                        :state="errors.length > 0 ? false:null"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--birthday-->
              <b-col md="12">
                <b-form-group
                  label="Ngày Sinh"
                  label-for="h-birthday"
                  label-cols-md="3"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Birthday"
                    :customMessages="{['required']: 'Không được để trống'}"
                  >
                      <b-form-datepicker
                        id="h-birthday"
                        v-model="form.birthday"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Chọn Ngày"
                      />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- gender -->
              <b-col md="12">
                <b-form-group
                  label="Giới Tính"
                  label-for="h-gender"
                  label-cols-md="3"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Gender"
                    :customMessages="{['required']: 'Không được để trống'}"
                  >
                      <b-form-select
                        id="h-gender"
                        v-model="form.gender"
                        :state="errors.length > 0 ? false:null"
                        :options="genderOptions"
                      >
                      </b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Giới thiệu"
                  label-for="h-description"
                  label-cols-md="3"
                >
                  <b-form-textarea 
                    id="h-description"
                    v-model="form.description"
                  />
                </b-form-group>
              </b-col>
              <!-- submit button -->
              <b-col cols="12" style="text-align: right">
                <b-button
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
                >
                  Cập Nhật
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, password, integer } from '@validations'
import {
  BCardTitle, BCardHeader, BInputGroup, BInputGroupPrepend, BContainer, BFormTextarea, BFormInput, BFormDatepicker, BFormGroup, BForm, BRow, BCol, BButton, BCard, BFormSelect, BFormSelectOption
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    BCardTitle,
    BCardHeader, 
    BInputGroup,
    BInputGroupPrepend,
    BContainer,
    BFormTextarea,
    BFormDatepicker,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BCard,
    FeatherIcon
},
  methods: {
    validationForm() {
      this.$refs.create.validate()
        .then(success => {
          if (success) {
            console.log('submit')
          } else {
            console.log('error ref')
          }
        })
    },
  },
  data() {
    return {
      genderOptions: [
        {
          value: null, text: 'Chọn Giới Tính...'
        },
        {
          value: 'male', text: 'Nam'
        },
        {
          value: 'female', text: 'Nữ'
        },
        {
          value: 'none', text: 'Không Xác Định'
        },
      ],
      integer,
      form: {
        gender: null,
      },
      required,
      email,
      password,
    }
  },
}
</script>
<style>
  .color-input{
    width: 50%
  }
</style>
